import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, IconButton, Input, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTableItem from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/constants";
import localizationStrings from "../utils/localizations";

// const columns = ;

export default function PushNotifications() {
  const [, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [guidanceId, setGuidanceId] = useState("");

  const [, reload] = useState({});

  useEffect(() => {}, []);

  const onSave = async (silent = false) => {
    if (!silent) setButtonLoading(true);
    try {
      const data = await axios.post(baseUrl + "sendPushNotif", {
        title: title,
        message: message,
        guidanceId: guidanceId,
      });

      if (data.data.status) {
        alert("Message sent successfully");
        setMessage("");
        setTitle("");
      } else {
        alert(data.data?.message || "Something went wrong!");
      }
      if (!silent) setButtonLoading(false);
    } catch (e) {
      if (!silent) setButtonLoading(false);
      alert(e?.response?.data?.message || e?.message || "Something went wrong");
      console.warn(e);
    }
  };

  return (
    <div style={{ height: "calc(100vh - 56px)", width: "100%", padding: 30 }}>
      {
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <TextField
            label={localizationStrings.EmployeeNoLeaveEmpty}
            variant="filled"
            value={guidanceId}
            onChange={(e) => setGuidanceId(e.target.value)}
          />

          <div style={{ height: 20 }} />
          <TextField label={localizationStrings.title} variant="filled" value={title} onChange={(e) => setTitle(e.target.value)} />

          <div style={{ height: 20 }} />
          <TextField label={localizationStrings.EnterYourMessage} variant="filled" value={message} onChange={(e) => setMessage(e.target.value)} />

          <div style={{ height: 20 }} />

          {buttonLoading ? (
            <div className="loader"></div>
          ) : (
            <Button onClick={() => onSave()} variant="contained">
              {localizationStrings.Send}
            </Button>
          )}
        </Box>
      }
    </div>
  );
}
