const localBaseUrl = "http://localhost/api/v1/";
const remoteBaseUrl = window.location.origin + "/api/v1/";

// const remoteBaseUrl = 'http://185.206.134.46/api/admin/';
// const remoteBaseUrl = 'https://mobappdev.arshdni.app/api/admin/';
// export const baseUrl = 'http://192.168.1.233/api/admin/';

export const baseUrl = process.env.NODE_ENV === "development" ? localBaseUrl : remoteBaseUrl;

console.log(process.env.NODE_ENV);
console.log(baseUrl);
