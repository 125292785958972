import LocalizedStrings from "react-localization";

const localizationStrings = new LocalizedStrings(require("./localization.json"));

export const changeLanguage = (lng) => {
  localStorage.setItem("language", lng);
  window.dispatchEvent(new Event("storage"));

  // localizationStrings.setLanguage(lng);
};
export const firstTrySetLang = () => {
  const lng = localStorage.getItem("language");
  console.log(lng);
  localizationStrings.setLanguage(lng || "en");
};

export default localizationStrings;
