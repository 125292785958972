import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, FormControl, InputLabel, ListItemIcon, Menu, MenuItem, Select } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import { baseUrl } from "../utils/constants";
import localizationStrings, { changeLanguage } from "../utils/localizations";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AbcIcon from "@mui/icons-material/Abc";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

export const drawerWidth = "300px";

function DashboardHome(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actions, setActions] = useState(null);
  const [reload, setReload] = useState({});
  const [loading, setLoading] = useState(false);
  const [tafheem, setTafheem] = useState([]);
  const params = useParams();
  const [version, setVersion] = useState("");

  const [mobileOpen, setMobileOpen] = React.useState(false);
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const checkRoleExists = (roles) => {
    const user_role = (JSON.parse(localStorage.getItem("user") || "{}") || {}).role;
    if (!user_role) return false;
    return roles.indexOf(user_role) > -1;
  };

  const getTafheem = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "tafheem");
      if (data.data.success) {
        setTafheem(data.data.data);
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };

  const getVersion = () => {
    axios.get(baseUrl.replace("api/v1/", "") + "version").then((res) => {
      setVersion(res.data);
    });
  };

  useEffect(() => {
    getTafheem();
    getVersion();
    const int = setInterval(() => {
      getTafheem(true);
    }, 60 * 1000);
    return () => clearInterval(int);
  }, []);

  const [__, ___, removeCookie] = useCookies(["jwt"]);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const logout = () => {
    removeCookie("jwt", { path: "/" });
    localStorage.setItem("user", null);
    navigate("/login");
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const data = await axios.get(baseUrl + "admin/checkLogin");
        if (!data.data.success) {
          logout();
        } else {
          localStorage.setItem("user", JSON.stringify(data.data.data));
          await new Promise((res) => setTimeout(res, 1000));
          setReload({});
          props.reload?.();
        }
      } catch (e) {
        console.warn(e);
      }
    };
    checkLoggedIn();
  }, [navigate]);

  function trim(s, c) {
    if (c === "]") c = "\\]";
    if (c === "^") c = "\\^";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp("^[" + c + "]+|[" + c + "]+$", "g"), "");
  }

  const drawer = (
    <div
      dir={localizationStrings.getLanguage() === "ar" ? "rtl" : "ltr"}
      style={{
        display: "flex",
        flexDirection: "column",
        fontFamily: localizationStrings.getLanguage() === "en" ? "Aldrich" : "GESndBook",
        width: drawerWidth,
        overflowX: "hidden",
      }}
    >
      <img
        src={require("./../assets/images/logo-tafheem.png")}
        style={{ width: parseInt(drawerWidth) / 2, marginTop: 20, marginBottom: 20, alignSelf: "center" }}
      />
      <div
        style={{
          width: drawerWidth,
          display: "flex",
          backgroundColor: "#f2f6f9",
          padding: "15px 20px",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ backgroundColor: "white", padding: "3px", borderRadius: "100px", width: "46px", height: "46px" }}>
          <Avatar src={require("./../assets/images/dummy-image.png")} style={{ backgroundColor: "#f2f6f9", width: "40px", height: "40px" }} />
        </div>
        <div style={{ marginLeft: "10px", marginRight: "10px", alignItems: "center", justifyContent: "center", display: "flex" }}>
          <Typography fontStyle={{ fontSize: "14px", color: "#0008" }}>{JSON.parse(localStorage.getItem("user") || "{}").name}</Typography>
        </div>
      </div>
      <List>
        <DrawerItem
          title={localizationStrings.Dashboard}
          icon={<DashboardIcon />}
          navigateUrl="dashboard"
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        {checkRoleExists(["admin"]) && (
          <DrawerItem
            title={localizationStrings.AdminUsers}
            icon={<DomainAddIcon />}
            navigateUrl="admin-users"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        )}
        {checkRoleExists(["admin"]) && (
          <DrawerItem
            title={localizationStrings.Books}
            icon={<LibraryBooksIcon />}
            navigateUrl="books"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        )}
        {checkRoleExists(["admin"]) && (
          <DrawerItem
            title={localizationStrings.Shabd}
            icon={<AbcIcon />}
            navigateUrl="shabd"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        )}
        {checkRoleExists(["admin"]) && (
          <DrawerItem
            title={localizationStrings.Notification}
            icon={<CircleNotificationsIcon />}
            navigateUrl="notification"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        )}
        {checkRoleExists(["admin"]) &&
          (loading ? (
            <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
              <div className="loader"></div>
            </div>
          ) : (
            tafheem.map((item) => (
              <DrawerItem
                title={item.title}
                icon={<PhoneAndroidIcon />}
                navigateUrl={"tafheem/" + item.title}
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
              />
            ))
          ))}
        {/* {checkRoleExists(["admin"]) && (
          <DrawerItem
            title={localizationStrings.PushNotification}
            icon={<CircleNotificationsIcon />}
            navigateUrl="push-notifications"
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        )} */}
        {version && <Typography style={{ color: "#0008", fontSize: 10, textAlign: "center", marginTop: 10 }}>v{version}</Typography>}
      </List>
    </div>
  );
  return (
    <div>
      <CssBaseline />

      <nav className={"drawer"}>
        <Box sx={{ display: { xs: "block", md: "none" } }} component="div">
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: "drawerPaper", root: "drawer" }}
          >
            <div
              style={{
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                marginTop: "5px",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <IconButton onClick={handleDrawerToggle} style={{}}>
                <CloseIcon />
              </IconButton>
            </div>
            {drawer}
          </Drawer>
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }} component="div">
          <Drawer classes={{ paper: "drawerPaper", root: "drawer" }} variant="permanent" anchor={theme.direction === "rtl" ? "right" : "left"}>
            {drawer}
          </Drawer>
        </Box>
      </nav>
      <Box
        sx={{
          marginLeft: { xs: 0, md: theme.direction === "ltr" ? drawerWidth : "0px" },
          marginRight: { xs: 0, md: theme.direction === "ltr" ? "0px" : drawerWidth },
        }}
        className="content"
      >
        <AppBar className="appBar" style={{ background: "#cc9767", color: "#000" }}>
          <Toolbar>
            <Box sx={{ display: { xs: "none", md: "block" } }} component="div">
              <div style={{ width: drawerWidth }}></div>
            </Box>
            <Box sx={{ display: { xs: "block", md: "none" } }} component="div">
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                style={{ [localizationStrings.getLanguage() === "ar" ? "marginLeft" : "marginRight"]: theme.spacing(2) }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
              {trim(global.location.pathname, "/") === "dashboard-home/company-login"
                ? localizationStrings.CompanyLogin
                : trim(global.location.pathname, "/") === "dashboard-home/push-notifications"
                ? localizationStrings.PushNotification
                : trim(global.location.pathname, "/") === "dashboard-home/books"
                ? localizationStrings.Books
                : trim(global.location.pathname, "/") === "dashboard-home/shabd"
                ? localizationStrings.Shabd
                : trim(global.location.pathname, "/") === "dashboard-home/admin-users"
                ? localizationStrings.AdminUsers
                : trim(global.location.pathname, "/") === "dashboard-home/notification"
                ? localizationStrings.Notification
                : global.location.pathname.includes("tafheem")
                ? params.title
                : "Dashboard"}
            </Typography>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              {actions}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem style={{ fontFamily: localizationStrings.getLanguage() === "en" ? "Aldrich" : "GESndBook" }} onClick={logout}>
                  {localizationStrings.Logout}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <div style={theme.mixins.toolbar} />
        <Outlet context={{ handleDrawerToggle, setActions }} />
      </Box>
    </div>
  );
}
// DashboardHome.propTypes = {
//   container: PropTypes.object,
// };
export default DashboardHome;

function DrawerItem({ title, navigateUrl, handleDrawerToggle, mobileOpen, icon }) {
  const navigate = useNavigate();
  return (
    <ListItem
      button
      onClick={() => {
        if (mobileOpen) handleDrawerToggle();
        navigate(navigateUrl);
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ fontFamily: localizationStrings.getLanguage() === "en" ? "Aldrich" : "GESndBook" }}
        primary={title}
        style={{ textAlign: localizationStrings.getLanguage() === "ar" ? "right" : "left" }}
      />
    </ListItem>
  );
}
