import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../utils/constants";
import moment from "moment";
import localizationStrings from "../utils/localizations";

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var sign = seconds < 0 ? "-" : "";
  seconds = Math.abs(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);

  var dDisplay = d > 0 ? d + ` ${localizationStrings.days} ` : "";
  var hDisplay = h > 0 ? h + ` ${localizationStrings.hrs} ` : "";
  var mDisplay = m > 0 && d <= 0 ? m + ` ${localizationStrings.min} ` : "";
  return (sign + dDisplay + hDisplay + mDisplay).trim() || `0 ${localizationStrings.min}`;
}
export default function Dashboard() {
  // const [statistics, setStatistics] = useState({
  //   TOTAL_TRIP_TIME: 0,
  //   TOTAL_TRIPS: 0,
  //   TOTAL_HAJJ: 0,
  //   TOTAL_COMPANIES: 0,
  //   TOTAL_TODAY_TRIPS_TIME: 0,
  //   TOTAL_TODAY_TRIPS: 0,
  //   TOTAL_TODAY_HAJJ: 0,
  //   TOTAL_TODAY_COMPANIES: 0,
  //   AVERAGE_TIME_TO_START: 0,
  //   AVERAGE_TIME_TO_ARRIVE: 0,
  //   AVERAGE_HAJJ_PER_DAY: 0,
  //   AVERAGE_COMPANIES_PER_DAY: 0,
  // });
  const [loading, setLoading] = useState(false);

  // const getStatistics = async (silent = false) => {
  //   if (!silent) setLoading(true);
  //   try {
  //     const data = await axios.get(baseUrl + "getStatistics", { params: { timezone: new Date().getTimezoneOffset() } });
  //     if (data.data.status) {
  //       setStatistics(data.data.result);
  //     }
  //     if (!silent) setLoading(false);
  //   } catch (e) {
  //     if (!silent) setLoading(false);
  //     console.warn(e);
  //   }
  // };
  // useEffect(() => {
  //   getStatistics();
  //   const int = setInterval(() => getStatistics(true), 30 * 1000);
  //   return () => clearInterval(int);
  // }, []);

  const formatTime = (mins) => {
    return moment.utc(mins * 60 * 1000).format("HH:mm");
  };

  if (loading) {
    return (
      <div className="tracking-container" style={{ alignItems: "center", justifyContent: "center" }}>
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <Box style={{ backgroundColor: "#fff", paddingTop: 15, height: "100vh" }}>
      <Typography sx={{ pt: 1.5, pb: 1.5, pl: 2, pr: 2, fontSize: 16, fontWeight: "600", color: "#4e4e4e" }}>
        Please use menu bar on the right to navigate to the desired page.
      </Typography>
      {/* <Typography sx={{ pt: 1.5, pb: 1.5, pl: 2, pr: 2, fontSize: 16, fontWeight: "600", color: "#4e4e4e" }}>
        {localizationStrings.Statistics}
      </Typography>
      <Box style={{ display: "flex", margin: 15, flexDirection: "column" }}>
        <Box style={{ flexDirection: "row", display: "flex", borderRadius: 2, marginBottom: 10, overflow: "hidden", boxShadow: "0px 1px 3px #0005" }}>
          <DashboardItem
            content={secondsToDhms(parseFloat("" + statistics.TOTAL_TRIP_TIME) * 60)}
            title={localizationStrings.TotalTripsTime + ":"}
            bg="#ffffffff"
            fg="#3d773a"
          />
          <DashboardItem
            content={
              (parseFloat(statistics.TOTAL_TRIPS?.toFixed?.(2)) || statistics.TOTAL_TRIPS) +
              " - " +
              (parseFloat(statistics.TOTAL_NOT_STARTED?.toFixed?.(2)) || statistics.TOTAL_NOT_STARTED)
            }
            title={localizationStrings.TotalTrips + ":"}
            bg="#f2f6f8"
            fg="#2181ed"
          />
          <DashboardItem
            content={parseFloat(statistics.TOTAL_HAJJ?.toFixed?.(2)) || statistics.TOTAL_HAJJ}
            title={localizationStrings.TotalHajj + ":"}
            bg="#fff"
            fg="#f9ac4d"
          />
          <DashboardItem
            content={parseFloat(statistics.TOTAL_COMPANIES?.toFixed?.(2)) || statistics.TOTAL_COMPANIES}
            title={localizationStrings.TotalCompanies + ":"}
            bg="#f2f6f8"
            fg="#8d8f92"
          />
        </Box>
        <Box style={{ flexDirection: "row", display: "flex", borderRadius: 2, marginBottom: 10, overflow: "hidden", boxShadow: "0px 1px 3px #0005" }}>
          <DashboardItem
            content={secondsToDhms(parseFloat("" + statistics.TOTAL_TODAY_TRIPS_TIME) * 60)}
            title={localizationStrings.TotalTodayTripsTime + ":"}
            bg="#fff"
            fg="#3d773a"
          />
          <DashboardItem
            content={
              (parseFloat(statistics.TOTAL_TODAY_TRIPS?.toFixed?.(2)) || statistics.TOTAL_TODAY_TRIPS) +
              " - " +
              (parseFloat(statistics.TOTAL_TODAY_NOT_STARTED?.toFixed?.(2)) || statistics.TOTAL_TODAY_NOT_STARTED)
            }
            title={localizationStrings.TotalTodayTrips + ":"}
            bg="#f2f6f8"
            fg="#2181ed"
          />
          <DashboardItem
            content={parseFloat(statistics.TOTAL_TODAY_HAJJ?.toFixed?.(2)) || statistics.TOTAL_TODAY_HAJJ}
            title={localizationStrings.TotalTodayHajj + ":"}
            bg="#fff"
            fg="#f9ac4d"
          />
          <DashboardItem
            content={parseFloat(statistics.TOTAL_TODAY_COMPANIES?.toFixed?.(2)) || statistics.TOTAL_TODAY_COMPANIES}
            title={localizationStrings.TotalTodayCompanies + ":"}
            bg="#f2f6f8"
            fg="#8d8f92"
          />
        </Box>
        <Box style={{ flexDirection: "row", display: "flex", borderRadius: 2, marginBottom: 10, overflow: "hidden", boxShadow: "0px 1px 3px #0005" }}>
          <DashboardItem
            content={secondsToDhms((parseFloat("" + statistics.AVERAGE_TIME_TO_START) || 0) * 60)}
            title={localizationStrings.AverageTimeToStart + ":"}
            bg="#fff"
            fg="#3d773a"
          />
          <DashboardItem
            content={secondsToDhms((parseFloat("" + statistics.AVERAGE_TIME_TO_ARRIVE) || 0) * 60)}
            title={localizationStrings.AverageTimeToArrive + ":"}
            bg="#f2f6f8"
            fg="#2181ed"
          />
          <DashboardItem
            content={parseFloat(statistics.AVERAGE_HAJJ_PER_DAY?.toFixed?.(2)) || statistics.AVERAGE_HAJJ_PER_DAY || 0}
            title={localizationStrings.AverageHajjPerDay + ":"}
            bg="#fff"
            fg="#f9ac4d"
          />
          <DashboardItem
            content={parseFloat(statistics.AVERAGE_COMPANIES_PER_DAY?.toFixed?.(2)) || statistics.AVERAGE_COMPANIES_PER_DAY || 0}
            title={localizationStrings.AverageCompaniesPerDay + ":"}
            bg="#f2f6f8"
            fg="#8d8f92"
          />
        </Box>
      </Box> */}
    </Box>
  );
}

function DashboardItem({ title, content, bg, fg }) {
  return (
    <Box style={{ display: "flex", flex: 1, backgroundColor: bg, padding: 15, flexDirection: "column", justifyContent: "space-between" }}>
      <Typography sx={{ color: "gray", fontSize: 12 }}>{title}</Typography>
      <Typography sx={{ color: fg, fontSize: 32, textAlign: "center", fontWeight: "300" }}>{content + ""}</Typography>
    </Box>
  );
}
