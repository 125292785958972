import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../utils/constants";
import localizationStrings from "../utils/localizations";

export default function Login() {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await axios.get(baseUrl + "admin/login", {
        params: { login, password: pass },
      });
      console.log(data.data);
      if (data.data.success) {
        navigate("/dashboard-home");
        localStorage.setItem("user", JSON.stringify(data.data.data));
      } else {
        setError(data.data?.message || "Invalid Creds");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data?.message || e.message || "Invalid Creds");
      console.warn(e);
    }
  };

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        const data = await axios.get(baseUrl + "admin/checkLogin");
        if (data.data.success) {
          navigate("/dashboard-home");
          localStorage.setItem("user", JSON.stringify(data.data.data));
        }
      } catch (e) {
        console.warn(e);
      }
    };
    checkLoggedIn();
  }, [navigate]);

  const onChangeLogin = useCallback((e) => {
    setLogin(e.target.value);
    setError("");
  }, []);
  const onChangePass = useCallback((e) => {
    setPass(e.target.value);
    setError("");
  }, []);

  return (
    <div className="login-container">
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <h2 className="active"> {localizationStrings.SignIn} </h2>
          {/* <h2 className='inactive underlineHover'>Sign Up </h2> */}

          <div className="fadeIn first">
            <img src={require("./../assets/images/logo-tafheem.png")} style={{ height: "150px", width: "auto" }} id="icon" alt="User Icon" />
          </div>

          <form onSubmit={submitForm}>
            <input
              type="text"
              id="login"
              className="loginText fadeIn second"
              name="login"
              placeholder={localizationStrings.UserName}
              value={login}
              onChange={onChangeLogin}
            />
            <input
              class="loginText"
              type="password"
              id="password"
              className="fadeIn third"
              name="login"
              placeholder={localizationStrings.Password}
              value={pass}
              onChange={onChangePass}
            />
            {!!error && <div className="error">{error}</div>}
            {loading ? (
              <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                <div className="loader" />
              </div>
            ) : null}{" "}
            <input type="submit" className="fadeIn fourth" value={localizationStrings.LogIn} />
          </form>
          {/* 
          <div id='formFooter'>
            <a className='underlineHover' href='#'>
              Forgot Password?
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
